<template>
  <div>
    <b-card>
      <div class="filter-wrap flex-between">
        <div class="flex-start search-filter-wrap">
          <el-input
              v-model="dataForm.searchStr"
              width="180"
              placeholder="请输入内容"
              clearable
              @clear="handleFilter"
          />
          <el-button
              size="small"
              type="primary"
              icon="el-icon-search"
              @click="handleFilter"
          >
            搜索
          </el-button>
        </div>
        <div>
          <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              @click="addOrUpdateHandle()"
          >
            新增
          </el-button>
        </div>
      </div>
      <div class="table-wrap">
        <el-table
            v-loading="dataListLoading"
            fit
            border
            highlight-current-row
            :data="dataList"
            stripe
            style="width: 100%"
        >
          <el-table-column
              prop="name"
              :show-overflow-tooltip="true"
              label="名称"
          />
          <el-table-column
              prop="explain"
              :show-overflow-tooltip="true"
              label="说明"
          />
          <el-table-column
              label="操作"
              width="160px"
          >
            <template v-slot="scope">
              <el-link
                  class="mr10"
                  :underline="false"
                  type="primary"
                  @click="addOrUpdateHandle(scope.row.id)"
              >
                <el-tooltip
                    content="修改"
                    placement="top"
                    effect="light"
                >
                  <i class="el-icon-edit" />
                </el-tooltip>
              </el-link>

              <el-link
                  class="mr10"
                  :underline="false"
                  type="primary"
                  @click="deleteHandle(scope.row.id)"
              >
                <el-tooltip
                    content="删除"
                    placement="top"
                    effect="light"
                >
                  <i class="el-icon-delete" />
                </el-tooltip>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            :current-page="dataForm.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="dataForm.pageSize"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="pageSizeChangeHandle"
            @current-change="pageCurrentChangeHandle">
        </el-pagination>
        <!-- 弹窗, 新增 / 修改 -->
        <edit-dialog v-if="editVisible" ref="editDialog" @refreshDataList="getList"></edit-dialog>
      </div>
    </b-card>
  </div>
</template>

<script>
import EditDialog from './chat-edit.vue'

export default {
  components: {
    EditDialog,
  },
  data() {
    return {
      editVisible: false,
      dataListLoading: false,
      dataList: [],
      total: 0,
      dataForm: {
        order: '', // 排序，asc／desc
        orderField: '', // 排序，字段
        pageNo: 1, // 当前页码
        pageSize: 10, // 每页数
        searchStr: '',
        flowId: Number(this.$route.query.flowId),
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.dataListLoading = true
      this.$http.post('/template/chat/page', this.dataForm).then(({ data: res }) => {
        this.dataListLoading = false
        if (res.code !== 0) {
          this.dataList = []
          this.total = 0
          this.$message.error(res.msg)
        } else {
          this.dataList = res.data.list
          this.total = res.data.total
        }
      }).catch(() => {
        this.dataListLoading = false
      })
    },
    handlePage(data) {
      this.dataForm.pageNo = data.page
      this.getList()
    },
    addOrUpdateHandle(id) {
      this.editVisible = true
      this.$nextTick(() => {
        this.$refs.editDialog.init(this.dataForm.flowId, id)
      })
    },
    handleFilter() {
      this.dataForm.pageNo = 1
      this.getList()
    },
    deleteHandle(id) {
      this.$confirm('确定进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`/template/chat/delete?id=${id}`).then(({ data: res }) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 500,
              onClose: () => {
                this.getList()
              },
            })
          }
        }).catch(() => {
        })
      }).catch(() => {
      })
    },
    pageSizeChangeHandle(val) {
      this.dataForm.pageNo = 1
      this.dataForm.pageSize = val
      this.getList()
    },
    pageCurrentChangeHandle(val) {
      this.dataForm.pageNo = val
      this.getList()
    },
  },
}
</script>

<style>
.el-tooltip__popper{
  max-width: 20%;
}
</style>

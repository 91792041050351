<template>
  <el-dialog
      :title="dataForm.id ? '编辑' : '新增'"
      :visible.sync="dialogVisible"
      width="620px"
      @close="dialogClose"
  >
    <el-form
        v-if="dialogVisible"
        ref="form"
        v-loading="loading"
        :model="dataForm"
        label-width="120px"
        :rules="dataRule"
    >
      <el-form-item
          label="名称"
          prop="name">
        <el-input v-model="dataForm.name" placeholder="名称"></el-input>
      </el-form-item>
      <el-form-item
          label="说明"
          prop="explain">
        <el-input type="textarea" v-model="dataForm.explain" placeholder="说明"></el-input>
      </el-form-item>

    </el-form>
    <div
        slot="footer"
        class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
          type="primary"
          :disabled="loading"
          @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      users: [],
      loading: false,
      dialogVisible: false,
      dataForm: {
        id: 0,
        flowId: 0,
        name: '',
        explain: '',
      },
    }
  },
  computed: {
    dataRule() {
      return {
        name: [
          { required: true, message: '名称不能为空。', trigger: 'blur' },
          {
            min: 3, max: 20, message: '名称长度在3到20个字符', trigger: 'blur',
          },
        ],
        explain: [
          {
            min: 0, max: 500, message: '说明长度在0到500个字符', trigger: 'blur',
          },
        ],
      }
    },
  },
  created() {
  },
  methods: {
    init(flowId, id) {
      this.dialogVisible = true
      this.dataForm.flowId = flowId
      if (id) {
        this.getInfo(id)
      }
    },
    // 获取信息
    getInfo(id) {
      this.$http.get(`/template/chat/info?id=${id}`).then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.dataForm = {
            ...this.dataForm,
            ...res.data,
          }
        }
      }).catch(() => {})
    },
    dialogClose() {
      this.dataForm = {
        id: 0,
        flowId: 0,
        name: '',
        explain: '',
      }
    },
    submitForm: debounce(function () {
      this.loading = true
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$http.post(!this.dataForm.id ? '/template/chat/create' : '/template/chat/update', this.dataForm).then(({ data: res }) => {
            if (res.code !== 0) {
              this.$message.error(res.msg)
            } else {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500,
                onClose: () => {
                  this.dialogVisible = false
                  this.loading = false
                  this.$emit('refreshDataList')
                },
              })
            }
          }).catch(() => {
            this.loading = false
          })
        }
        this.loading = false
      })
    }, 1000, { leading: true, trailing: false }),
  },
}
</script>
